import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { FaSearch } from "react-icons/fa"
import { Icon } from "@iconify/react"

import tw from "twin.macro"

const SearchResult = props => {
  // 全記事データ取得 //
  const tempData = useStaticQuery(graphql`
    query SearchData {
      allMicrocmsPosts(
        sort: { fields: [createdAt], order: DESC }
        limit: 10000
      ) {
        edges {
          node {
            title
            postsId
          }
        }
      }
    }
  `)

  const [data, setData] = useState([])
  useEffect(() => {
    const temp = []
    tempData.allMicrocmsPosts.edges.map(e => {
      temp.push(e.node)
    })
    setData(temp)
  }, [])

  // 表示非表示の切り替え //
  const [className, setClassName] = useState("")
  useEffect(() => {
    let id
    if (props.focus && props.value !== "") {
      id = setTimeout(() => {
        setClassName("active")
      }, 100)
    } else {
      id = setTimeout(() => {
        setClassName("")
      }, 100)
    }
    return () => {
      clearTimeout(id)
    }
  }, [props.focus, props.value])

  // 検索処理 //
  const [result, setResult] = useState([])
  const search = () => {
    const value = props.value.toLowerCase()
    const temp = data.filter(e => {
      const target = `
        ${e.title.toLowerCase()}
      `
      // ${e.tags.join(" ").toLowerCase()}
      // ${e.keywords.toLowerCase()}

      return target.indexOf(value) !== -1
    })
    setResult(temp)
  }
  useEffect(() => {
    if (props.value !== "") {
      search()
    } else {
      setResult([])
    }
  }, [props.value])

  if (result.length === 0 && props.value === "") {
    return null
  }

  return (
    <div tw="absolute top-12 z-10 bg-white shadow-2xl right-2 rounded lg:w-[60vw] transition-transform duration-150 ease-linear transform translate-y-24">
      <span tw="flex items-center p-4 border-b">
        <b>{result.length}</b>
        件ヒットしました
      </span>
      <ul tw="max-h-80 overflow-y-scroll">
        {result.map(e => {
          return (
            <li key={e.postsId} tw="p-2 border-b">
              <Link to={`/posts/${e.postsId}/`}>
                <span includes={props.value}>{e.title}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const Search = () => {
  const [focus, setFocus] = useState(false)
  const [value, setValue] = useState("")
  const onFocus = () => {
    setFocus(true)
  }
  const onBlur = () => {
    setFocus(false)
  }
  const onChange = e => {
    setValue(e.target.value)
  }
  return (
    <div
      tw="flex flex-shrink border-gray-200 border rounded-full px-2 py-2 items-center	relative"
      focus={focus}
    >
      <FaSearch color="gray" tw="mr-4 ml-2 flex-shrink-0" />
      <input
        tw="w-full focus:outline-none"
        type="text"
        placeholder="検索する"
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
      <SearchResult focus={focus} value={value} />
    </div>
  )
}

export default Search
