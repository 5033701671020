import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ComponentName = () => {
  return (
    <StaticImage
      src="../assets/icons/instagram_icon.png"
      alt="Instagram"
      tw="rounded-md max-w-xs lg:max-w-full mx-auto"
      fullWidth
    />
  )
}

export default ComponentName
