import React from "react"
import { Link } from "gatsby"

import InstaImage from "./InstaImage"
import MicrocmsPosts from "./MiacrocmsPosts"

import tw from "twin.macro"

const H1 = tw.h1`
  text-lg
  p-3
  mb-4
  rounded-md
  bg-gray-300
`

const Li = tw.li`
  px-3
  my-0
  py-2
`

const SideBar = () => {
  return (
    <div>
      <H1>Instagram</H1>
      <div tw="mb-8 text-center">
        <a
          href="https://www.instagram.com/manpukuhuhu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstaImage />
        </a>
      </div>
      {/* <H1>カテゴリー</H1>
      <ul tw="divide-y my-4" >
        <Li><Link to="/">カテゴリ１</Link></Li>
        <Li><Link to="/">カテゴリ２</Link></Li>
        <Li><Link to="/">カテゴリ３</Link></Li>
      </ul> */}
      <H1>最新の記事</H1>
      <MicrocmsPosts />
    </div>
  )
}

export default SideBar
