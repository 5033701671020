import React, { useState } from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import Search, { SearchIcon } from "./Search"
import { Icon } from "@iconify/react"
import { FaSearch } from "react-icons/fa"

const H1 = tw.h1`
  text-center
  text-2xl
  md:text-4xl
`

const Header = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  return (
    <div tw="flex flex-wrap items-center justify-items-stretch">
      {isSearchOpen ? (
        <div tw="flex w-full">
          <div tw="flex-grow">
            <Search />
          </div>
          <button tw="ml-4 mr-4 lg:mr-0" onClick={() => setIsSearchOpen(false)}>
            <Icon icon="clarity:window-close-line" />
          </button>
        </div>
      ) : (
        <>
          <div tw="flex-grow lg:w-3/4">
            <Link to="/">
              <H1>manpuku</H1>
              <h2 tw="text-center text-xs mt-2">○ 茨城夫婦のグルメブログ ○</h2>
            </Link>
          </div>
          <div tw="lg:w-1/4 mr-4 lg:mr-0 hidden lg:block">
            <Search />
          </div>
          <button tw="block lg:hidden" onClick={() => setIsSearchOpen(true)}>
            <FaSearch color="gray" tw="mr-2 sm:mr-8 flex-shrink-0" />
          </button>
        </>
      )}
    </div>
  )
}

export default Header
