import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import tw from "twin.macro"

const Copyright = tw.p`
  text-xs
  text-center
  mt-5
`

const Wrapper = tw.div`
  bg-gray-600
  py-3
  text-white
  text-center
`

const Title = tw.h1`
  text-center
  text-2xl
  my-2
  text-white
`

const Footer = () => {
  return (
    <Wrapper>
      <Link to="/">
        <Title>manpuku</Title>{" "}
        <h2 tw="text-center text-xs my-2 text-white">
          ○ 茨城夫婦のグルメブログ ○
        </h2>
      </Link>
      <ul tw="flex-wrap flex justify-center pt-2">
        <li tw="px-2">
          <a
            href="https://www.instagram.com/manpukuhuhu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../assets/icons/Instagram_AppIcon_Aug2017.png"
              alt="Instagram"
              width="32"
              height="32"
            />
          </a>
        </li>
        <li tw="px-2">
          <a
            href="https://twitter.com/manpukuhuhu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../assets/icons/twitter.png"
              alt="Twitter"
              tw="rounded"
              width="32"
              height="32"
            />
          </a>
        </li>
      </ul>
      <ul tw="flex-wrap flex justify-center text-xs text-white">
        <li tw="px-2">
          <Link to="/contact">
            <span>お問い合わせ</span>
          </Link>
        </li>
      </ul>
      <Copyright>
        © {new Date().getFullYear()}, manpuku All Rights Reserved
      </Copyright>
    </Wrapper>
  )
}

export default Footer
