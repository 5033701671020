import React from 'react';
import { Link, graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"

const Li = tw.li`
  px-3
  my-0
  py-2
`;

const MicrocmsPosts = () => {
  const data = useStaticQuery(graphql`
    query AllPosts {
      allMicrocmsPosts(sort: { fields: [publishedAt], order: DESC }, limit: 5) {
        edges {
          node {
            title
            postsId
          }
        }
      }
    }
  `)
  const posts = data.allMicrocmsPosts.edges;
  return (
    <ul tw="divide-y" >
    {posts.map((item) => {
      const post = item.node;
      return <Li><Link to={`/posts/${post.postsId}`}>{post.title}</Link></Li>
    })}
  </ul>
  )
}

export default MicrocmsPosts;