import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

const Layout = ({ children }) => {
  return (
    <>
      <div className="global-wrapper">
        <header tw="mb-6">
          <Header />
        </header>
        <main tw="flex flex-wrap">
          <article tw="w-full lg:w-3/4">{children}</article>
          <aside tw="w-full lg:w-1/4">
            <Sidebar />
          </aside>
        </main>
      </div>
      <footer tw="py-0">
        <Footer />
      </footer>
    </>
  )
}

export default Layout
